export default {
    INIT: "@@INIT",
    ON_LOAD: "ON_LOAD",
    LOAD_AGENCY: "LOAD_AGENCY",
    LOAD_PROPERTIES: "LOAD_PROPERTIES",
    LOAD_SEARCH_INFO: "LOAD_SEARCH_INFO",
    PROPERTY_DETAIL: "PROPERTY_DETAIL",
    DETAIL_DETECTION: "DETAIL_DETECTION",
    PROPERTY_LIST: "PROPERTY_LIST",
    LOAD_LOCALIZATION: "LOAD_LOCALIZATION",
    LOAD_PARAMATERS: "LOAD_PARAMATERS",
    TRANSLATE_PROPERTY_FEATURES: "TRANSLATE_PROPERTY_FEATURES",
    TRANSLATE_PROPERTY_TYPES: "TRANSLATE_PROPERTY_TYPES"
}
